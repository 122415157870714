<template>
  <el-dialog :close-on-press-escape='false' :close-on-click-modal='false' id="allFinanceModel" :show-close="false"
    :visible.sync="showTabModel">
    <!-- 标题-->
    <div class="model_title flex align-center justify-between">
      <div class="flex align-center">
        <span class="text-16 text-bold text-black">{{modelTitle}}</span>
      </div>
      <i @click="closeTabModel" class="el-icon-close" style="color: #C0BEBE;cursor: pointer;"></i>
    </div>

    <!-- 内容 -->
    <div class="margin-tb-24">
      <el-table :header-cell-style="{background:'#F5F6F7',color:'#333333',fontsize:'14px'}" :data="tableList.list"
        stripe height="500" style="width: 100%;">
        <el-table-column type="index" align="center" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="order5_time" align="center" label="申请时间" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.order5_time*1000 | getDate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="admin_acc" align="center" label="发起人">
        </el-table-column>
        <el-table-column prop="order5_reason" align="center" label="退款理由">
          <template slot-scope="scope">
            <el-button type="text" size="small" style="color: #4E9F5B;">查看
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="order5_picture" align="center" label="附件">
          <template slot-scope="scope">
            <el-button type="text" size="small" style="color: #4E9F5B;">
              查看</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="order5_money" align="center" label="金额">
        </el-table-column>
        <el-table-column prop="address" align="center" label="操作">
          <template slot-scope="scope">
            <el-button v-if="scope.row.order5_state=='0'" @click="approve(scope.row.order5_id,'1')" type="text"
              size="small">同意</el-button>
            <el-button v-if="scope.row.order5_state=='0'" @click="approve1(scope.row.order5_id,'2')" type="text"
              size="small" style="color: #FF1A2E;">拒绝</el-button>
            <span v-if="scope.row.order5_state=='1'">已审批</span>
            <span v-if="scope.row.order5_state=='2'">已驳回</span>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页功能 -->
    <!-- total	总条目数
    layout 组件布局，子组件名用逗号分隔 total, sizes, prev, pager, next, jumper
    page-sizes	每页显示个数选择器的选项设置 [10, 20, 30, 40]
    size-change	页数 改变时会触发
    current-change	每页码 改变时会触发
    current-page 当前页数，支持 .sync 修饰符 -->
    <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="listQuery.pageNo"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="listQuery.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="rows">
    </el-pagination> -->
     <!-- <el-pagination
      @size-change="handleSizeChange"
      @current-change="tableList.page"
      :current-page="tableList.page"
      :page-size="tableList.limit"
      :total="tableList.count">
    </el-pagination> -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableList.page"
      :page-size="tableList.limit"
      :total="tableList.count">
    </el-pagination>
  </el-dialog>

</template>

<script>
  export default {
    data(){
      return{
        /* 分页参数*/
        pages:1, //总页数
        rows:1, //总条数
      }
    },
    components: {
    },
    props: {
      showTabModel: Boolean,
      modelTitle: String,
      icon: Boolean,
      tableList: Object
    },
    data() {
      return {
        pageSizes: [10, 20, 30],
        showInfoModel: false,
        title: '退款理由',
        content: '',

        /* 附件*/
        showImgModel: false,
        imgTitle: '退款申请附件',
        imgContent: [],

        /* 审批*/
        approveData: {
          order5_id: '',
          order5_state: '', //1: 通过, 2: 驳回
        },
        // order5_examine_remark:''//审批附加备注说明(拒绝理由)
        /* 审批--拒绝*/
        showWrite: false,
        writeTitle: '审批-拒绝原因'

      }
    },
    methods: {
        // handleSizeChange(pageSize) {
        //     console.log('组件', pageSize)
        //     this.pageSize = Number(pageSize)
        //     this.$emit('changeCurenPage',{
        //       page:this.tableList.page,
        //       limit:pageSize
        //     })
        // },
        handleSizeChange(val) {
         console.log(`每页 ${val} 条`);
         this.tableList.limit = val 
        },
        handleCurrentChange(val) {
          this.tableList.page = val 
        },
        closeTabModel(){
            this.$emit('closeTabModel')
        }
    }
  }
</script>

<style>
  @import url("//at.alicdn.com/t/font_2789416_xzv7kdkm3vo.css");

  #allFinanceModel .el-dialog {
    border-radius: 8px;
    width: 780px !important;
    /* overflow-y: scroll; */
  }

  #allFinanceModel .el-dialog__header {
    display: none !important;
  }

  #allFinanceModel .el-dialog__body {
    padding: 24px !important;
  }
</style>

<template>
<div id="financeManage">
  <!-- 头部搜索 -->
  <div class="bg-white searchDom flex align-center justify-between">
    <div class="flex align-center margin-bottom-24">
      <el-select @change="changeValue" style="margin-right: 24px;width: 120px;" v-model="financeTabDat.category"
        filterable placeholder="订单类型">
        <el-option v-for="item in this.$conf.financeOrderType" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select @change="changeTime" style="margin-right: 24px;width: 120px;" v-model="financeTime1" filterable
        placeholder="快捷时间">
        <el-option v-for="item in this.$conf.financeorderTime" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <div class="flex">
        <el-date-picker @change="changeTime" style="width: 360px;" v-model="financeTime2" type="daterange"
          range-separator="-" start-placeholder="开始日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
          :picker-options="pickerBeginDateBefore" :default-value="timeDefaultShow" end-placeholder="结束日期">
        </el-date-picker>
      </div>
    </div>
    <div class="flex align-center margin-bottom-24 header_search">
      <div class="flex align-center solid margin-right-24 padding-lr-16" style="height:32px;width: 300px;">
        <el-input @input="changeKeyword" v-model="financeTabDat.keyword" placeholder="请输入用户昵称查询" suffix-icon="search"></el-input>
        <i class="el-icon-search" style="cursor: pointer;" @click="changeValue"></i>
      </div>
      <el-button style="height: 32px;width: 68px;padding: 0;color: #4E9F5B !important;border-color: #4E9F5B;">导出</el-button>
      <el-badge :value="allwait" :max="99" class="item" style="margin-right:16px;margin-left:12px">
        <el-button @click="showModel('退款申请列表')"
          style="height: 32px;padding: 0 10px;color: #FFFFFF;background-color: #4E9F5B;">退款列表
        </el-button>
      </el-badge>
    </div>
  </div>
  <!-- 搜索列表 -->
  <div class="searchList">
    <div class="margin-bottom-24 padding-lr-16 align-center" style="background-color: #F5F6F7;">
      <!-- <el-collapse v-model="activeNames" @change="handleChange" accordion>
        <el-collapse-item style="background-color: #F5F6F7;" name="1">
          <echartLine typeLine="caiwu" company="元" :xData="xData" :datas="datas" :key="timer"></echartLine>
        </el-collapse-item>
      </el-collapse>-->
      <span style="padding-right:20px">财务折线图</span>
      <el-switch v-model="stateValue" active-color="#07C160" inactive-color="#333"></el-switch>
      <span style="padding-left:25px" v-show="dataList.length!='0'" class="text-bold text-16">总计：</span><span style="color: #FF1A2E;">{{finance_money_count}}</span>
      <echartLine v-show="stateValue!=false" typeLine="caiwu" company="元" :xData="xData" :datas="datas" :key="timer"></echartLine>
    </div>
    <div class="invoice-content">
        <ul class="row align-center margin-bottom-26 flex-start">
            <li class="bg-white flex justify-between margin-bottom-16"
             v-for="(v,i) in dataList" :key="i">
                <div>
                    <div class="invoice-orderNum">
                      <h1>订单编号:{{v.order_number}}</h1>
                    </div>
                    <div class="flex justify-between content">
                      <div class="invoice-info flex justify-between">
                        <div class="flex">
                          <h4 class="mb-0">用户昵称:</h4>
                          <p>{{v.user_nickname}}</p>
                        </div>
                        <div class="flex">
                          <h4 class="mb-0">时间:</h4>
                          <p>{{v.finance_time*1000|getDate}}</p>
                        </div>
                        <div class="flex">
                          <h4 class="mb-0">用户ID:</h4>
                          <p>{{v.user_id}}</p>
                        </div>
                        <div class="flex">
                          <h4 class="mb-0">联系电话:</h4>
                          <p>{{v.order_addr_phone}}</p>
                        </div>
                      </div>
                      <div class="right text-end align-center justify-between">
                        <h5 class="mb-2" v-if="v.finance_money>'0'" >+{{v.finance_money}}</h5>
                        <h5 class="mb-2" v-if="v.finance_money<'0'" style="color:#DC3545 !important">-{{v.finance_money}}</h5>
                        <span v-if="v.finance_money>'0'" class="text-white bg-success">订单</span>
                        <span v-if="v.finance_money<'0'" class="text-white bg-danger">退款</span>
                      </div>
                    </div>
                </div>
            </li>

        </ul>
    </div>
  </div>
  <div class="pageNum" id="pageNum">
    <Pagination style="margin-top:20px"
    v-show="rows>1"
    :total="rows"
    @change = "getFinanceList"
    :page.sync= "financeTabDat.page"
    :limit.sync="financeTabDat.limit"/>
  </div>
      

      <!-- 表格弹窗 -->
      <!-- <tableModel @changeList="changeList" @changeCurenPage="changeCurenPage" @closeTabModel='closeTabModel_' :showTabModel="showTabModel" :modelTitle="modelTitle"
      :tableList="tableList"/> -->
      <!-- 退款列表表格 -->
      <el-dialog :close-on-press-escape='false' :close-on-click-modal='false' id="allFinanceModel" :show-close="false"
        :visible.sync="showTabModel">
        <!-- 标题-->
        <div class="model_title flex align-center justify-between">
          <div class="flex align-center">
            <span class="text-16 text-bold text-black">退款申请列表</span>
          </div>
          <i @click="closeTabModel" class="el-icon-close" style="color: #C0BEBE;cursor: pointer;"></i>
        </div>
        <!-- 内容 -->
        <div class="margin-tb-24">
          <el-table :header-cell-style="{background:'#F5F6F7',color:'#333333',fontsize:'14px'}" :data="tableList"
            stripe height="500" style="width: 100%;">
            <el-table-column type="index" align="center" label="序号" width="50">
            </el-table-column>
            <el-table-column prop="order5_time" align="center" label="申请时间" width="180">
              <template slot-scope="scope">
                <span>{{ scope.row.order5_time*1000 | getDate }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="admin_acc" align="center" label="发起人">
            </el-table-column>
            <el-table-column prop="order5_reason" align="center" label="退款理由">
             <template slot-scope="scope">
                <el-button @click="showReason(scope.row.order5_reason)" type="text" size="small" style="color: #4E9F5B;">查看
                </el-button>
              </template>
            </el-table-column>
            <el-table-column prop="order5_picture" align="center" label="附件">
              <template slot-scope="scope">
                <el-button @click="showpicture(scope.row.order5_picture)" type="text" size="small" style="color: #4E9F5B;">查看
                </el-button>
              </template>
            </el-table-column>
            <el-table-column prop="order5_money" align="center" label="金额">
            </el-table-column>
            <el-table-column prop="address" align="center" label="操作">
              <template slot-scope="scope">
                <el-button v-if="scope.row.order5_state=='0'" @click="approve(scope.row.order5_id,'1')" type="text"
                  size="small">同意</el-button>
                <el-button v-if="scope.row.order5_state=='0'" @click="approve1(scope.row.order5_id,'2')" type="text"
                  size="small" style="color: #FF1A2E;">拒绝</el-button>
                <span v-if="scope.row.order5_state=='1'">已审批</span>
                <span v-if="scope.row.order5_state=='2'">已驳回</span>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <Pagination
          v-show="rows>1"
          :total="rows"
          @change = "getFinanceRefund"
          :page.sync= "getModelTab.page"
          :limit.sync="getModelTab.limit"/>
    </el-dialog>

    <!-- 退款理由 -->
    <el-dialog id="imgModelBox" title="退款申请附件" :visible.sync="showImgModel" :close-on-press-escape='false' :close-on-click-modal='false'
    append-to-body :before-close="closeImgModel">
   
       <div class="spadding-16 text-14 text-333 margin-top-24 text-left flex flex-wrap">
         <div v-for="(item,index) in imgContent" :key="index">
           <img :src="item" style="margin-bottom: 16px;margin-right: 10px;width: 120px;height: 120px;" >
         </div>
       </div>
    </el-dialog>

    <!-- 退款理由 -->
    <!-- 退款理由 -->
    <el-dialog id="infoModelBox" title="退款理由" :visible.sync="showInfoModel" :close-on-press-escape='false' :close-on-click-modal='false'
    append-to-body :before-close="closeInfoModel">
      <div  class="solid padding-16 text-14 text-333 margin-top-24 radius-4 text-left" style="min-height: 225px;">
        <div v-html="content"></div>
      </div>
    </el-dialog>

    <el-dialog id="writeModelBox" title="审批-拒绝原因" :visible.sync="showWriteModel" :close-on-press-escape='false' :close-on-click-modal='false'
    append-to-body :before-close="closeWriteModel">

      <div class="solid padding-16 text-14 text-333 margin-top-24 radius-4 text-left" style="min-height: 225px;">
        <el-input @input="writeNum" type="textarea" :rows="10" placeholder="请输入拒绝理由" v-model="textarea" :maxlength='255'>
        </el-input>
        <span style="display: inline-block;width: 100%;text-align: right;margin-top: 4px;">{{num}}/255</span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.stop="sendRes">确 认</el-button>
      </span>
    </el-dialog>
</div>    
</template>
<script>
import {financeList,financeRefund,financeRefundExamine} from '@/api/http'
import Pagination from '@/components/pagination'
import tableModel from './component/tableModel.vue'
export default {
    data(){
      return{
        timer:'',
        textarea: '',
        num:0,
        activeNames:[],
        /* 修改日期选择器结束日期*/
        pickerBeginDateBefore: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
        },
        stateValue:false,
        /* 分页参数*/
        pages:1, //总页数
        rows:1, //总条数
        allwait:'',//待审批退款条数
        timeDefaultShow: "",
        financeTabDat: {
          time: '',
          category: '',
          keyword: '',
          page: 1,
          limit: 16
        },
        //附件
        showImgModel: false,
        imgContent: [],
        //退款理由

        showInfoModel: false,
        content: '',

        dataList:[],//表格数据
        finance_money_count: '', //总计
        financeTime1: '', //快捷选择时间
        financeTime2: '', //日期范围
        /* 退款列表弹窗参数*/
        showTabModel: false,
        tableList: {},
        getModelTab:{
          page:1,
          limit:8
        },
        /* 审批*/
        approveData: {
          order5_id: '',
          order5_state: '', //1: 通过, 2: 驳回
        },
        // order5_examine_remark:''//审批附加备注说明(拒绝理由)
        /* 审批--拒绝*/
        showWriteModel: false,
        /* 折叠面板*/
        activeNames: [],
        xData: [], //X轴
        datas: [] //折线数据
      }
    },
    created(){
        this.timeDefaultShow = new Date();
        this.timeDefaultShow.setMonth(new Date().getMonth() - 1);
        this.getFinanceList()
        this.getFinanceRefund()
    },
    mounted(){
    },
    methods:{
        /* 折叠面板*/
        handleChange(val) {
          console.log(val);
          this.timer = new Date().getTime()
        }, 
        //获取财务列表
        getFinanceList(){
            let that = this
            let tabDat = {}
            for (let item in this.financeTabDat) {
              if (that.financeTabDat[item] != '' && that.financeTabDat[item] != '999') {
                tabDat[item] = that.financeTabDat[item]
              }
            }
            financeList(tabDat).then(res=>{
                console.log('财务列表：',res)
                let {chart} = res.data.data
                if(chart){
                  this.xData = chart[0]
                  this.datas = chart[1]
                  console.log(this.xData,this.datas)
                }
                let {list,page,count,limit}=res.data.data
                console.log(res.data.code,list,page,count,limit)
                if(res.data.code == '1' && list){
                  this.rows = count
                  this.dataList = list
                  this.timer = new Date().getTime()
                  this.finance_money_count = res.data.data.finance_money_count
                }else if(res.data.code=='1'&&res.data.data.csv_file){
                  window.open(res.data.data.csv_file)
                  this.financeTabDat.limit=this.rows
                }
            })
        },
          /* 改变获取列表参数 */
        changeValue() {
          this.financeTabDat.page = 1
          this.getFinanceList()
        },
        /* 清空搜索框 */
        changeKeyword(e){
          if(e.length=='0'){
            this.changeValue()
          }
        },
        /* 选择时间*/
        changeTime(e) {
          console.log(e)
          if (e == '999' || e == '7' || e == '15' || e == '30') {
            this.financeTime2 = ''
            this.financeTabDat.time = e
          } else {
            this.financeTime1 = ''
            // var date = new Date(e[0])
            var time1 = Date.parse(new Date(e[0]+' 00:00:00')) / 1000
            var time2 = Date.parse(new Date(e[1]+' 23:59:59')) / 1000
            this.financeTabDat.time = [time1, time2].toString()
          }
          this.changeValue()
        },
        /* 折叠面板*/
        handleChange(val) {
          console.log(val);
          this.timer = new Date().getTime()
          // this.getFinanceList()
        },
        //打开弹窗
        showModel(title){
            this.modelTitle = title
            this.showTabModel = true
            this.getModelTab.page = 1
            this.getFinanceRefund()
        },
        //获取退款列表
        getFinanceRefund(){
            financeRefund(this.getModelTab).then(res=>{
                console.log('退款列表',res.data)
                let {list,page,count,limit}=res.data.data
                console.log(res.data.code,list,page,count,limit)
                if(res.data.code == '1'){
                  this.rows = count
                  this.tableList = list
                  this.allwait = res.data.data.wait
                }
            })
        },
        /* 退款理由 */
        showReason(reason) {
          this.content = reason
          this.showInfoModel = true
        },
        /* 附件*/
        showpicture(arr) {
          this.showImgModel = true
          this.imgContent = arr
        },
        closeImgModel() {
          this.showImgModel = false
        },
        closeInfoModel() {
          this.showInfoModel = false
        },
        //退款成功，刷新列表
        changeList(){
            this.getFinanceRefund()
        },
        //关闭退款弹窗
        closeTabModel(e){
            console.log('点击退款弹窗')
            this.getFinanceList()
            this.showTabModel = false
        },
        //分页
        changeCurenPage(e){
          this.getModelTab.page=e.page
          this.getModelTab.limit=e.limit
          this.getfinanceRefund()
        },
        //同意、拒绝退款
        approve(id,num){
          this.approveData.order5_id = id
          this.approveData.order5_state = num
          this.sendAsk('同意')
        },
        sendAsk(text){
          let that = this
          this.$confirm("此操作将永久修改订单状态, 是否继续?", "提示", {
				     confirmButtonText: "确定",
				     cancelButtonText: "取消",
				     type: "warning",
			    })
			    .then(() => {
            financeRefundExamine(this.approveData).then(res=>{
              console.log('是否同意退款',res.data)
              if(res.data.code == '1'){
                  that.$message.success(text)
                  that.changeList()
              }else{
                  that.$message.error(res.info)
              }
            })
			    })
			    .catch(() => {
			    	this.$message({
			    		type: "info",
			    		message: "已取消操作",
			    	});
			    });
        },
        approve1(id,num){
          this.showWriteModel = true
          this.approveData.order5_id = id
          this.approveData.order5_state = num
        },
        sendReason(info) {
          console.log('点击')
          this.approveData.order5_examine_remark = info
          this.showWriteModel = false
          this.sendAsk('已拒绝退款')
        },
        closeWriteModel(){
          this.showWriteModel = false
          //重置拒绝表单
          this.textarea = ''
          this.num = 0
        },
        writeNum(e){
          this.num=e.length
        },
        sendRes(){
          this.sendReason(this.textarea)
        }
    },
    components:{
        Pagination,
        tableModel
    }
}
</script>
<style>
#financeManage .header_search .el-input__inner {
  height: 20px !important;
  border: none !important;
  padding: 0 !important;
}

.invoice-content {
  position: relative;
  /* height: 295px; */
}
.invoice-content li {
  width: 24%;
  float: left;
  margin-bottom: 7px;
  display: block;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  margin-right: 16px;
  background-color: #fff;
  }
.invoice-content .invoice-orderNum{
  background-color: #0f2c14 !important;
  height: 60px;
}
.invoice-content h1{
    font-size: 18px;
    text-align: center;
    color: #fff;
    line-height: 60px;
    font-weight: normal;
}
.invoice-content li .content{
  padding: 16px;
}
.invoice-content li h4 {
    font-size: 14px;
    color: #616366;
    font-weight: 700;
    margin-bottom: 3px;
    line-height: 20px;
}
.invoice-content li p {
    font-size: 14px;
    color: #333;
}
.invoice-content li .content h1 {
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
    color: #323233;
    margin-right: 8px;
}
  .invoice-content li:last-child {
    border: 0px; }
  .invoice-content li span {
    right: 15px;
    display: block;
    font-size: 12px;
    background: #fff;
    padding: 2px 10px;
    border-radius: 16px;
    display: inline-block; }

.invoice-content .invoice-user-img img {
  border-radius: 56px; }

.invoice-content .invoice-info {
  position: relative;
  display: block; }
  .invoice-content .invoice-info p {
    margin-bottom: 5px;margin-left: 10px; }
.invoice-content .right{
  position: absolute;
  right: 20px;
  top: 50%;
}    
.bg-success {
    background-color: #51BB25 !important;
}
</style>